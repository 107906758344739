import logo from './logo.svg';
import './App.css';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import firebaseApp from './firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import Rbpipeline from './components/rbpipeline/rbpipeline';
import { connectFirestoreEmulator } from 'firebase/firestore';

const defaultUser = {loggedIn: false, email: ""};
const loginOptions = [{id: 0, option: "login"}, {id: 1, option: "sign up"}];

const UserContext = React.createContext({});
const UserProvider = UserContext.Provider;
const UserConsumer = UserContext.Consumer;

const auth = getAuth(firebaseApp);

function onAuthStateChange(callback) {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      callback({loggedIn: true, email: user.email, uid: user.uid});
    } else {
      callback({loggIn: false});
    }
  });
}

function login(username, password, activeUser) {
  if (activeUser) {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, username, password)
      .then(() => resolve())
      .catch(error => reject(error));
    });
  } else {
    return new Promise ((resolve, reject) => {
      createUserWithEmailAndPassword(auth, username, password)
      .then(() =>
        resolve()
      )
      .catch(error => reject(error))
    })
  }
}

function logout() {
  signOut(auth);
}

function LoginView({onClick, error, size}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [activeUser, setActiveUser] = useState(true) // true - sign in, false - sign up

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: size.height}}>
      <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', borderRadius: '16px', boxShadow: '0 0 4px 0 #c3c3c3', padding: '24px'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
          <h2 style={{color: activeUser ? 'black' : '#a0a0ab', transition: '100ms linear', cursor: 'pointer'}} onClick={() => setActiveUser(true)}>Login</h2>
          {/* <h2 style={{color: activeUser ? '#a0a0ab' : 'black', transition: '100ms linear', cursor: 'pointer'}} onClick={() => setActiveUser(false)}>Sign up</h2> */}
        </div>
        <a style={{margin: '8px'}}>You should know the credentials :)</a>
        <input
          style={{}}
          onChange={event => {
            setUsername(event.target.value);
          }}
        />
        <input
          style={{}}
          type="password"
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
        <button
          onClick={() => {
            onClick(username, password, activeUser);
          }}
        >
          Login
        </button>
        <span>{error}</span>
      </div>
    </div>
  )
}

function TopNavBar({onClick}) {
  const user = useContext(UserContext);
  return (
    <div style={{backgroundColor: 'white', top: 0, width: '100%', display: 'flex', justifyContent: 'flex-end', lineHeight: '40px', boxShadow: '1px 1px 4px 1px #cacaca'}}>
      <span>Hey {user.email}, 👋 </span>
      <span onClick={onClick} style={{margin: '0 24px', cursor: 'pointer', cursor: 'pointer', color: 'grey'}}>Logout</span>
    </div>
  );
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function App() {
  const [user, setUser] = useState({loggedIn: false});
  const [error, setError] = useState("");

  const size = useWindowSize();

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    }
  }, []);

  const requestLogin = useCallback((username, password, activeUser) => {
    login(username, password, activeUser).catch(error => setError(error.code));
  });

  const requestLogout = useCallback(() => {
    logout();
  }, []);

  if (!user.loggedIn) {
    return <div>
      <LoginView onClick={requestLogin} error={error} size={size}/>
    </div>
  };

  return (
    <UserProvider value={user}>
      <TopNavBar onClick={requestLogout} />
      <div style={{marginTop: '16px'}}>
        <Rbpipeline user={user} />
      </div>
    </UserProvider>
  );
}

export default App;