import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, onSnapshot, QuerySnapshot } from "firebase/firestore";
import firebaseApp from '../../firebase';
import './rbpipeline.css';
import rbru from '../../assets/rbru.png';
import burger from '../../assets/burger.png';
import search from '../../assets/search.png';
import popular from '../../assets/popular.png';

const db = getFirestore(firebaseApp);

function Rbpipeline (user) {

    const topSectionOptions = [{id: 0, name: 'Медиа'}, {id: 1, name: 'Инвестиции'}, {id: 2, name: 'Коммьюнити'}];
    const [activeTopSection, setActiveTopSection] = useState(0);
    const secondLevelOptions = [
        {id: 0, name: 'Новости'},
        {id: 1, name: 'Тренды'},
        {id: 2, name: 'Операционка'},
        {id: 3, name: 'Dig(it)al'},
        {id: 4, name: 'Поддержка'},
        {id: 5, name: 'Венчур'},
        {id: 6, name: 'Поддержка'},
        {id: 7, name: 'Венчур'},
        {id: 8, name: 'Люди'},
        {id: 9, name: 'Личные финансы'},
        {id: 10, name: 'Техника'},
        {id: 11, name: 'Карьера'},
        {id: 12, name: 'Образ жизни'},
    ];
    const lenta = [
        {id: 0, time: '12:30', name: 'Как специалистам по платному трафику'},
        {id: 1, time: '12:30', name: 'Шесть важных качеств, на каокторые обращают внимание работодатели на собеседовании'},
        {id: 2, time: '12:30', name: 'С помощью в трудоустройстве: Microsoft запустит бесплатную программу для обучения цифровым навыкам и это вообще длинный заголовок должен быть'}, 
        {id: 3, time: '12:30', name: 'Как специалистам по платному трафику'},
        {id: 4, time: '12:30', name: 'Шесть важных качеств, на каокторые обращают внимание работодатели на собеседовании'}
    ]

    let topPanel = <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{height: '22px'}}>
                <img src={burger} alt='burger' />
            </div>
            <div style={{marginLeft: '32px'}}>
                <img src={search} alt='search' />
            </div>
        </div>
        <div style={{marginTop: 'auto'}}>
            <img src={rbru} alt='rbru' style={{height: '40px'}}/> 
        </div>
        <div style={{display: 'flex'}}>
            <p style={{marginRight: '24px', fontSize: '14px', lineHeight: '22px', cursor: 'pointer'}}>Подписаться</p>
            <p style={{marginRight: '24px', fontSize: '14px', lineHeight: '22px', cursor: 'pointer'}}>Вход</p>
        </div>
    </div>

    let topSections = <div style={{borderBottom: '1px solid #000000'}}>
        <div style={{display: 'flex', marginTop: '24px', maxWidth: '400px', justifyContent: 'space-between', marginLeft: 'auto', marginRight:'auto'}}>
        {topSectionOptions.map(tso => {
            return <div key={tso.id} style={{fontSize: '22px', lineHeight: '28px', fontWeight: '700', color: tso.id === activeTopSection ? '#AD475C' : '#000000', cursor: 'pointer', transition: 'color 100ms linear'}} onClick={() => setActiveTopSection(tso.id)}>
                <div>{tso.name}</div>
                <svg width="22" height="7" viewBox="0 0 22 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '14px', display: tso.id === activeTopSection ? 'block' : 'none', marginBottom:'-1px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <path d="M11 1L2 6.5H10.75H19.5L11 1Z" fill="white" stroke="black"/>
                    <path d="M3.77344 6H17.8086L19.5 7H2L3.77344 6Z" fill="white"/>
                </svg>
            </div>
        })}
        </div>
    </div>

    let secondLevelNav = <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
        <div style={{width: '80%', overflowX: 'scroll', position:'relative', lineHeight: '28px'}}>
            <div style={{display: 'flex', width: '1200px', justifyContent: 'space-between'}}>
                {secondLevelOptions.map(slo => {
                    return <div key={slo.id}>
                        <div>{slo.name}</div>
                    </div>
                })}
            </div>
            <div style={{position: 'absolute', right: 0, top: 0, height: '20px', width: '60px', marginRight: '-5px', backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))'}}></div>
        </div>
        <div style={{backgroundColor: '#C2C2C2', color: 'white', padding: '4px 16px'}}>
            Реклама
        </div>
    </div>

    let topContentSection = <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{flex: 1, padding: '0 24px'}}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                <div style={{fontWeight: 700, fontSize: '32px', lineHeight: '40px', letterSpacing: '-0.02em'}}>Лента</div>
                <div style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px', marginLeft: '24px'}}>История Публикаций</div>
            </div>
            <div style={{marginTop: '32px', height: '400px', overflow: 'scroll'}}>
                {lenta.map(lentaPost => {
                    return <div key={lentaPost.id} style={{display: 'flex', padding: '24px 0 32px 0', borderBottom: '1px solid #cccccc'}}>
                        <div style={{fontSize: '14px', lineHeight: '22px', fontWeight: 500, color: '#999999'}}>{lentaPost.time}</div>
                        <div style={{fontSize: '16px', lineHeight: '22px', fontWeight: 700, marginLeft: '16px'}}>{lentaPost.name}</div>
                    </div>
                })}
            </div>
        </div>
        <div style={{flex: 1, padding: '0 24px'}}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                <div style={{fontWeight: 700, fontSize: '32px', lineHeight: '40px', letterSpacing: '-0.02em'}}>Популярное</div>
                <div style={{fontWeight: 500, fontSize: '16px', lineHeight: '24px', marginLeft: '24px'}}>Лучшее за неделю</div>
            </div>
            <div style={{backgroundImage: `url(${popular})`, height: '400px', backgroundRepeat: 'no-repeat', marginTop: '32px', position: 'relative'}}>
                <div style={{position:'absolute', bottom: 0, color: 'white', padding: '16px 40px'}}>
                    <div style={{fontSize: '18px', lineHeight: '20px', fontWeight: 700}}>01.03.2022</div>
                    <div style={{fontSize: '18px', lineHeight: '20px', fontWeight: 700, marginTop: '8px'}}>Как специалистам по платному трафику получать более высокую зарплату</div>
                    <div style={{fontSize: '14px', lineHeight: '20px', fontWeight: 500, marginTop: '8px'}}>Какие технологии нужны девелоперам и сколько на этом можно заработать.</div>
                </div>
            </div>
        </div>
    </div>

    let horizontalScrollSection = <div style={{marginTop: '80px', display: 'flex', borderTop: '2px solid #000000', borderBottom: '2px solid #000000', padding: '40px 0 56px 0'}}>
        <div>
            <div style={{width: 'fit-content', color: 'white', lineHeight: '18px', fontSize:'14px', padding: '4px 12px', backgroundColor: '#AD475C'}}>Партнерский материал</div>
            <div style={{marginTop: '8px'}}>Стартовал прием заявок на участие в туристическом хакатоне Moscow Travel Hack 2021</div>
        </div>
        <div>
            <div style={{width: 'fit-content', color: 'white', lineHeight: '18px', fontSize:'14px', padding: '4px 12px', backgroundColor: '#AD475C'}}>Тренды</div>
            <div style={{marginTop: '8px'}}>Новый формат, как легко перевести бизнес на удаленную работу</div>
        </div>
        <div>
        <div style={{width: 'fit-content', color: 'white', lineHeight: '18px', fontSize:'14px', padding: '4px 12px', backgroundColor: '#AD475C'}}>Финансы</div>
            <div>BELUGA GROUP огласила финансовые показатели за 2020 год</div>
        </div>
    </div>

    return (
        <div>
            {/* Header */}
            <div style={{paddingBottom: '16px', boxShadow: '0 8px #c2c2c2'}}>
                <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                    {topPanel}
                    {topSections}
                    {secondLevelNav}
                </div>
            </div>
            {/* Content */}
            <div>
                <div style={{width: '80%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '48px'}}>
                    {topContentSection}
                    {horizontalScrollSection}
                </div>
            </div>
        </div>
    )
}

export default Rbpipeline;