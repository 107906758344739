import { initializeApp } from "firebase/app"

const firebaseApp = initializeApp({
    apiKey: "AIzaSyAhyursV-kLQTlF_3W7N_mZY8wTYL2C6jk",
    authDomain: "max8v-eb3c1.firebaseapp.com",
    databaseURL: "https://max8v-eb3c1.firebaseio.com",
    projectId: "max8v-eb3c1",
    storageBucket: "max8v-eb3c1.appspot.com",
    messagingSenderId: "491182533683",
    appId: "1:491182533683:web:d283f5cc0277e78154c746",
    measurementId: "G-L813C0F1M3"
});

export default firebaseApp;